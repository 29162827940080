$(document).foundation(); 

jQuery(document).ready(function () {

  /**
  * Functions on page load
  */

  initJs();

});

function initJs() {
wg_moveUp();   
}

//Toggle Menu button  
    $('.menu-icon').on('click', function(e) {
      $('.menu-icon').toggleClass("open");
      e.preventDefault();
    });

    /**
 * Zum Seitenanfang springen
 */

function wg_moveUp() {

  $("#toplink").click(function (event) {
    event.preventDefault();
    $('html, body').animate({
      scrollTop: $("body").offset().top
    }, 1000);
  });

} // end function moveUp()